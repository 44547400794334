import React from 'react';

import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    maxWidth: '400px',
    margin: theme.spacing(1),
    backgroundColor: 'black',
    height: '50px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
  },

  input: {
    width: '100%',
    maxWidth: '400px',
  },
}));

const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  margin-top: 32px;
`;

export default function AboutMe({ data, location }) {
  const classes = useStyles();
  return (
    <Layout pageName="about-me" location={location}>
      <SEO title="About Me" />
      <AvatarContainer>
        <img
          src={data.imageSharp.original.src}
          style={{
            marginBottom: 0,
            width: 100,
            borderRadius: `5px`,
          }}
        />
      </AvatarContainer>
      <Content>
        <div>
          <p>
            Hi there! I'm Thomas Duffy, a Staff Software Engineer at a tech
            company in sunny Irvine, CA. I have a true passion for all things
            web and technology. Since 2011, I've been actively involved in web
            development and online marketing, and every day has been an exciting
            adventure.
          </p>
          <p>
            I'm thrilled to have this platform to not only share my knowledge
            and give back to the community, but also to further my own growth as
            a developer.
          </p>
          <h3> Connect with me </h3>
          <div>
            <a
              href="https://www.linkedin.com/in/tomduffytech/"
              target="__blank"
            >
              Linkedin Profile
            </a>
          </div>
          <div>
            <a href="https://github.com/duffman85" target="__blank">
              Github Profile
            </a>
          </div>
          <div>
            <a href="mailto: tomduffy1985@gmail.com">
              Email: tomduffy1985@gmail.com
            </a>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imageSharp(fixed: { src: { regex: "/profile-pic.jpg/" } }) {
      id
      original {
        height
        src
        width
      }
    }
  }
`;
